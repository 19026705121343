import React from "react";
import { GrLinkedin, GrInstagram, GrMail } from "react-icons/gr";
import { FiMail } from "react-icons/fi";
import "./styles/UnderConstruction.css"

 

function UnderConstruction() {
  const szIcon = 50;

  const logos = [
    { id: "LinkedIn", icon: <GrLinkedin size={szIcon} className="BottomSection__icon" />, link: "https://www.linkedin.com/company/buildtivity" },
    { id: "Mail", icon: <GrMail size={szIcon} className="BottomSection__icon" />, link: "mailto:info@buildtivity.dk" },
    { id: "Instagram", icon: <GrInstagram size={szIcon} className="BottomSection__icon" />, link: "https://www.instagram.com/buildtivity/" }
  ];

  return (
    <div style={{ backgroundImage: `url("images/mainpage/img2.jpg")`,}} className="under-construction">
      <h1>Under Construction</h1>
      <h2>Follow on LinkedIn, Instagram, or Mail</h2>
      <div className="BottomSection__logoGrid">
        {logos.map((logo, index) => (
          <div key={index} className={logo}>
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              {logo.icon}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UnderConstruction;


