
import React from 'react';
import Signup from '../Redundant Code/Signup';
import UploadTable from './Upload/UploadTable';
import ProdStart from './Upload/ProdStart';

function Dev() {

  


    return (
        <div>
        {/* <Signup /> */}
        {/* <UploadTable /> */}
        <ProdStart />

        </div>
    );

    
}

export default Dev;
